<script lang="ts">
  export default {
    props: {
      button: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentImageUrl: this.button.imageUrl,
      };
    },
    methods: {
      handleMouseOver() {
        this.currentImageUrl = this.button.hoverImageUrl;
      },
      handleMouseLeave() {
        this.currentImageUrl = this.button.imageUrl;
      },
    },
  };
  </script>

<template>
    <div>
      <img
        class="group transition duration-300 ease-in-out"
        :src="currentImageUrl"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
      />
    </div>
</template>
  
  