<script lang="ts">
import { defineComponent, computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import NavbarTs from "./components/NavbarInd.vue";


export default defineComponent({
    name: "App",
    components: {
        NavbarTs,
        FooterInd: defineAsyncComponent(() => import('./components/FooterInd.vue')) ,
    },
    setup() {
        const route = useRoute();
        const currentNavbar = computed(() => {
        // Muestra NavbarJs si la ruta es /blog, de lo contrario muestra NavbarTs
        return route.path.startsWith("/blog") ? "NavbarJs" : "NavbarTs";
        });

        const currentFooter = computed(() => {
        // Muestra FooterJs si la ruta es /blog, de lo contrario muestra FooterTs
        return route.path.startsWith("/blog") ? "FooterJs" : "FooterTs";
        });
        
        return {
            currentNavbar,
            currentFooter,
            route,
            NavbarTs,
        };
    },
    methods: {
        showBanner() {
        const _hsp = (window as any)._hsp || [];
        _hsp.push(["showBanner"]);
        },
    },
});
</script>

<template class="flex flex-col no-scroll w-full bg-white">
    <transition name="fade">
        <div :key="route.path" class="flex flex-col h-full no-scroll justify-center w-full bg-white">
            <NavbarTs  />
            <main style="min-height:1500px">
                <router-view :key="route.path" v-slot="{ Component }">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </main>
            <FooterInd />
            <div class="fixed bottom-2 right-2 z-50">
                <a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
                    <img src="./pages/pagesImages/wha-icon.png" width="56" height="55" loading="eager" alt="WhatsApp" class="w-[56px]"/>
                </a>
            </div>
            <div class="flex justify-center items-center cursor-pointer fixed bottom-2 left-2 z-50" @click="showBanner" title="Configuración de cookies">
                <img src="./static/img/logo-h-white.png" width="45" height="49" loading="eager" alt="Cookies Humanitas" class="w-[45px] cursor-pointer"/>
            </div>
        </div>
    </transition>
</template>

<style scoped>
.no-scroll {
  overflow-x: hidden; /* Esto desactiva el desplazamiento horizontal */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.175s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en versiones de Vue anteriores */ {
  opacity: 0.5;
}
</style>
